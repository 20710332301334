export default function getOfflineMessage() {
  let offlineMsgDiv = document.createElement("div");
  offlineMsgDiv.id = "offlineMessage";
  offlineMsgDiv.style.top = 0;
  offlineMsgDiv.style.width = "100%";
  offlineMsgDiv.style.backgroundColor = "red";
  offlineMsgDiv.style.color = "white";
  offlineMsgDiv.style.textAlign = "center";
  offlineMsgDiv.style.padding = "10px";
  offlineMsgDiv.textContent =
    "Looks like you are offline. Please check your internet connection, and try again later.";

  return offlineMsgDiv;
}
