import getOfflineMessage from "../helpers/getOfflineMessage";

window.addEventListener("offline", () => {
  let body = document.querySelector("body");
  const offlineMsgDiv = getOfflineMessage();
  body.insertBefore(offlineMsgDiv, body.firstChild);
  document.getElementById("main-container").classList.add("disabled");
});

window.addEventListener("online", () => {
  document.getElementById("offlineMessage").remove();
  document.getElementById("main-container").classList.remove("disabled");
});
